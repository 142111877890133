import React, { useRef } from 'react';
import styled from 'styled-components';
import Heading from '../components/Heading';

//@ts-ignore
import SOURCE from '../assets/imagegroup.png'

export const Container = styled.div`
    display: flex;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 10;
    justify-content: center;
    overflow: hidden;
    padding: 200px 0;

    @media(max-width: 600px){
        .info{
            flex-direction: column;
        }
    }
    
    .content{
        /* padding-top: 400px; */
        max-width: 1000px; 

        &.animation{
            .heading{
                animation: slideFromBottom 500ms both;
            }
            .image img{
                animation: fadeIn 300ms  both;
            }
        }
        &:not(.animation){
            .heading{
                opacity: 0;
                animation: fadeOut 500ms 
                animation-delay: 0ms;
            }
            .image img{
                opacity: 0;
                animation: fadeOut 500ms 
                animation-delay: 0ms;
            }
         
        }

        .info {
            display: flex;
          //  flex-direction: column;

         
            .details{
                flex: 1;
                padding: 20px 70px;
                flex-direction: column;
                display: flex;
                justify-content: center;

                &.hidden{
                    opacity: 0;
                }
                &:not(.hidden){
                    opacity: 1;
                    animation: slideFromBottom 1s;
                }
                
            }
            .image{
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                &.hidden{
                    opacity: 0;
                }
                &:not(.hidden){
                    opacity: 1;
                    animation: fadeIn 1s;
                }

                img{
                    width: 400px;
                }
            }
           
        }
    }

    .left{

        padding: 20px;
        display: flex;
        align-items: center;

        .info{
            max-width: 600px;
        }
    }
    .right{

    }

  
`;


const CenterWithFeatures2= ({align,title,description,image}:any) => {

    const ref = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLDivElement>(null);
    const detailsRef = useRef<HTMLDivElement>(null);

    document.addEventListener('scroll',() => {
    
        if(!ref.current )
            return;

        let offsetTop = ref.current.getBoundingClientRect().top

        var top  = window.pageYOffset || document.documentElement.scrollTop
        var height = window.innerHeight;


        if(offsetTop > height){
            ref.current.classList.remove('animation');
        }
        else if(offsetTop < height*0.5){
            ref.current.classList.add('animation');
        }
      

    });


  return <Container>
      <div className="content" ref={ref}>
        
        <div className='info'>
          
            <div ref={detailsRef} className='details'>
                <Heading align="center" color="#333"  bold title="Lazer" size={6}/>
                <Heading style={{animationDelay: '250ms'}} align="center" color="#333"  bold title="Playground, Esportes e Piscina" size={2}/>
                <Heading style={{animationDelay: '500ms'}} align="center" color="#333" title={`Diversas instalaçoes para você se divertir junto à sua família! \n\r Brinquedos, quadra de volei de areia e piscinas adulta e infantil`} size={4}/>
            </div>

            <div className='image'>
            <img style={{animationDelay: '700ms'}} src={SOURCE}/>
        </div>

        </div>

     

      
      </div>
    
  </Container>;
}

export default CenterWithFeatures2;