import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  
    /* pointer-events: none;
    user-select: none; */
  

 
         /* position: absolute;  */
        /* left: 0;
        right: 0;
        top: 0;
        bottom: 0; */
        display: flex;
        width: 100%;
        position: relative;
        z-index: 10;
        justify-content: center;


        svg{
            width: 100%;
        }

        .solid{
            flex:1;
            background-color: #ffffff;
        }

`;


const Shape= () => {

    const points = [
        {
            x: 0,
            y: 0,
            type: "LINE"
        },
        {
            x: 200,
            y: 0,
            cx1: 80,
            cy1: 20,
            cx2: 120,
            cy2: 20,
            type: "CURVE"
        },
        {
            x: 200,
            y: 20,
            type: "LINE"
        },
    ]

    const width = 200;
    const height = 20;

    var path = `M 0 ${height}`;

    points.map(point => {
        if(point.type === "LINE"){
            path += ` L ${point.x} ${point.y}`
        }
        else if(point.type === "CURVE"){
            path += ` C ${point.cx1} ${point.cy1} ${point.cx2} ${point.cy2} ${point.x} ${point.y}`
        }
    })

  return <Container>
 
                
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox={`0 0 ${width} ${height}`} fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d={path} fill="#ffffff"/>
                </svg>
            
            {/* <div className='solid'></div> */}


  </Container>;
}

export default Shape;