import React, { useRef,useEffect } from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';

//@ts-ignore
import PICTURE from '../assets/home3.png'
//@ts-ignore
import PLANT from '../assets/monstera.png'
//@ts-ignore
import SKY from '../assets/sky.jpg'
//@ts-ignore
import LOGO from '../assets/logo.png'

import SCROLLANIMATION from '../assets/scrollAnimation.json'

import Heading from '../components/Heading';

export const Container = styled.div`
    display: flex;
    
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow: hidden;

    .scrollIcon{
        position: fixed;
        bottom: 25px;
        display: flex;
        width: 100%;
        background: transparent;
        z-index: 200;
    }
    
    .left{

        background-color: #0005;
        flex: 1;
        /* padding-left: 60px; */
      //  margin-top: -100px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
        width: 100%;

        .info{
          //  max-width: 600px;
             padding: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
          
            .logo{
                width: 200px;
            }

            button{
                padding: 10px 50px;
                border-radius: 30px;
                font-size: 20px;
                border: none;
                color: #ffffff;
                background-color: #799B13;
                cursor: pointer;
                transition: 200ms;
                margin-top: 10px;
               

                &:hover{
                    background-color: #304500;
                }
            }

        }
    }
    .right{

    }

    .background{
        user-select: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
      
      
        svg{
            width: 100%
        }

        .shape{
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
           
            svg{
                width: 100%;
                transform: translateY(70vh)
            }
        }

        .sky{
            width: 100%;
            height: 100%;
            z-index: 0;
        }

        .image{
            position: absolute;
            right: 0;
            top: 0;
            width: 170vh;
            //height: 650px;
            //margin-right: -100px;
            z-index: 2;

            @media(max-width: 1000px){
                &{
                    right: -40%;
                   
                }
            }
            @media(max-width: 600px){
                &{
                    right: -150%;
                    margin-top: -100px;
                }
            }
        }

  
        .plant-left{
           
            left: 0;
            position: absolute;
            z-index: 3;
            margin-bottom: -100px;
            width: 400px;
        }
       
    }
`;


const Home= () => {

    const ref = useRef<HTMLDivElement>(null);
    const pictureRef = useRef<HTMLImageElement>(null)
    const plantLeftRef = useRef<HTMLImageElement>(null)
    const infoRef =   useRef<HTMLDivElement>(null);  
    const leftRef =  useRef<HTMLDivElement>(null);  


    const skyRef =  useRef<HTMLImageElement>(null); 

	const onScroll = () => {
  		var top  = window.pageYOffset || document.documentElement.scrollTop
        	var height = window.innerHeight;
     
        if(ref.current && skyRef.current && leftRef.current && infoRef.current && pictureRef.current && plantLeftRef.current){
            // ref.current.style.bottom = (top/3)+"px";
            // pictureRef.current.style.bottom = (top/5)+"px";
            // plantLeftRef.current.style.bottom = (top/2.5-50)+"px";
            // infoRef.current.style.transform = `translateY(${(top/-3)+"px"})`;
            // skyRef.current.style.transform = `translateY(${(top/-2)+"px"})`;
            // leftRef.current.style.backgroundColor = `rgba(0,0,0,${((1-top/height)*0.4).toString()})`


            ref.current.style.bottom = (top/4)+"px";
           // pictureRef.current.style.bottom = (top/2.5)+"px";
           pictureRef.current.style.top = (top/-2.5)+"px";
            plantLeftRef.current.style.bottom = (top/4-50)+"px";
            infoRef.current.style.transform = `translateY(${(top/-3)+"px"})`;
            skyRef.current.style.transform = `translateY(${(top/-5)+"px"})`;
            leftRef.current.style.backgroundColor = `rgba(0,0,0,${((1-top/height)*0.5).toString()})`
            //ref.current.style.opacity = Number(1-top/height).toString();
        }

	}

    document.addEventListener('scroll',onScroll)

	useEffect(() => {
		onScroll()
	},[ref.current , skyRef.current ,leftRef.current ,infoRef.current ,pictureRef.current ,plantLeftRef.current])

  return <Container>

        <div className='scrollIcon'>
            <Lottie options={{
                loop: true,
                autoplay: true,
                animationData: SCROLLANIMATION
            }}
            height={50}
            width={50}
            />
        </div>

      <div className="left" ref={leftRef}>
          <div className='info' ref={infoRef}>
              <img src={LOGO} className="logo"/>
              <Heading align='center' style={{animation: 'slideFromBottom 300ms'}} color="#ffffff" bold title="Green Ville Residencial" size={1}/>
              <Heading align='center' style={{animation: 'slideFromBottom 300ms 300ms both  '}} color="#ffffff" title={`63 lotes e uma estrutura completa \n
               para viver em família!`} size={5}/>
            <button style={{animation: 'fadeIn 500ms 600ms both  '}} onClick={() => {
                //@ts-ignore
                window.open('https://wa.me/5518997494540?text="Olá tenho interesse!"', '_blank').focus()
            }}>Tenho Interese</button>
            
        </div>
      </div>
      {/* <div className="right">

      </div> */}
      <div className='background'>
        <img className='plant-left' src={PLANT} ref={plantLeftRef}/>
          <img className='image' src={PICTURE} ref={pictureRef}/>
          <div className='shape'  ref={ref}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 982" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1500 455V17.4719C1258.29 -32.9316 1103.81 31.5851 853.982 135.924L853.981 135.925C801.159 157.986 744.073 181.827 681 206.738C378.767 326.108 123.386 316.732 0 290.945V455V500V982H1500V500V455Z" fill="#799B13"/>
            </svg>
         </div>
         <img className='sky' src={SKY} ref={skyRef}/>
      </div>
  </Container>;
}

export default Home;