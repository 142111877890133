import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    height: 100vh;
    width: 100%;
    pointer-events: none;
    user-select: none;
`;


const Empty= () => {
  return <Container>
     
    
  </Container>;
}

export default Empty;