import React, { useRef } from 'react';
import styled from 'styled-components';
import Heading from '../components/Heading';

//@ts-ignore
import MAP from '../assets/upvision.png'

export const Container = styled.div`
    display: flex;
    
    width: 100%;
    position: relative;
    z-index: 10;
    overflow: hidden;
    background-color: #799B13;
    justify-content: center;

    .content{

        width: 100%;
        max-width: 1000px; 
        padding: 20px;
        display: flex;
        flex-direction: column;

        &.animation{
            .heading{
                animation: slideFromBottom 500ms both;
            }
            .image img{
                animation: fadeIn 300ms  both;
            }
        }
        &:not(.animation){
            .heading{
                opacity: 0;
                animation: fadeOut 500ms 
                animation-delay: 0ms;
            }
            .image img{
                opacity: 0;
                animation: fadeOut 500ms 
                animation-delay: 0ms;
            }
         
        }

    .details{
        flex: 1;
        padding: 20px 70px;
        flex-direction: column;
        display: flex;
        justify-content: center;
        width: 100%;

        &.hidden{
            opacity: 0;
        }
        &:not(.hidden){
            opacity: 1;
            animation: slideFromBottom 1s;
        }
        
    }
    .image{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        /* transform: translateY(-100px); */
        max-height: 400px;

        &.hidden{
            opacity: 0;
        }
        &:not(.hidden){
            opacity: 1;
            animation: fadeIn 1s;
        }

        .img {
            transform-style: preserve-3d;
            transition: all 100ms ;
            position: relative;
            width: 800px;
            height: 600px;
        }
        img{
            position: absolute;
            width: 800px;

            &:nth-child(2){
                transform: translateZ(-2px);
                filter: blur(1px) invert(20%);
            }
            &:nth-child(3){
                transform: translateZ(-4px);
                filter: blur(1px) invert(20%);
            }
            &:nth-child(4){
                transform: translateZ(-6px);
                filter: blur(1px) invert(20%);
            }
            &:nth-child(5){
                transform: translateZ(-20px);
                filter: invert(100%) blur(40px) opacity(0.5);
            }
        }
    }
    
}
    .background{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        display: flex;
        z-index: -1;
        flex-direction: column;

        svg{
            width: 100%;
        }

        .solid{
            flex:1;
            background-color: #ffffff;
        }
    }


    @media (max-width: 800px){
        .image {
            transform: scale(0.7);
        }   
    }
`;


const Map= () => {

    const ref = useRef<HTMLDivElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    document.addEventListener('scroll',() => {
    
        if(!ref.current || !imgRef.current)
            return;

        let offsetTop = ref.current.getBoundingClientRect().top

        imgRef.current.style.transform = `rotateX(60deg) rotateZ(${offsetTop/6}deg)`

        var top  = window.pageYOffset || document.documentElement.scrollTop
        var height = window.innerHeight;

       

      
            if(offsetTop > height){
                ref.current.classList.remove('animation');
            }
            else if(offsetTop < height*0.5){
                ref.current.classList.add('animation');
            }
          
         

    });


  return <Container>
      <div className="content" ref={ref}>
       
            <div className='details'>
                <Heading align="center" color="#ffffff"  bold title="63" size={1}/>
                <Heading style={{animationDelay: '250ms'}} align="center" color="#ffffff"  bold title="Lotes a disposição" size={3}/>
                <Heading style={{animationDelay: '500ms'}} align="center" color="#ffffff" title={`Espaço infantil livre, lúdico, divertido e organizado para as crianças poderem socializar e brincar à vontade com conforto e segurança.`} size={4}/>
            </div>
            <div className='image'>
                <div className="img" ref={imgRef}>
                    <img style={{animationDelay: '1s'}} src={MAP}/>
                    <img style={{animationDelay: '1.5s'}} src={MAP}/>
                    <img style={{animationDelay: '1.5s'}} src={MAP}/>
                    <img style={{animationDelay: '1.5s'}} src={MAP}/>
                    <img style={{animationDelay: '1.5s'}} src={MAP}/>
                </div>
            </div>
      
      </div>
    

      <div className='background'>
           
                {/* <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 1500 491" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1500 17.4719V500H0V290.945C123.386 316.732 378.767 326.108 681 206.738C744.074 181.827 801.159 157.985 853.982 135.924C1103.81 31.5851 1258.29 -32.9316 1500 17.4719Z" fill="#ffffff"/>
                </svg>
           
            <div className='solid'></div> */}
      </div>
    
  </Container>;
}

export default Map;