import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Heading from '../components/Heading';

//@ts-ignore
import FEATURE1 from '../assets/feature1.png'

//@ts-ignore
import FEATURE2 from '../assets/feature2.png'

//@ts-ignore
import FEATURE3 from '../assets/feature3.png'

//@ts-ignore
import FEATURE4 from '../assets/feature4.png'

//@ts-ignore
import FEATURE5 from '../assets/feature5.png'

//@ts-ignore
import FEATURE6 from '../assets/feature6.png'


const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    position: relative;
    z-index: 10;
   
    background-color: #799B13;
    overflow: hidden;

    .content {

        display: flex;
        transition: 200ms;
        height: 100vh;
     

        .scrollview{
            justify-content: flex-start;
            display: flex;
            flex: 1;
            margin-left: 100px;
        }
        .back-left{
            display: flex;
            position: absolute;
            left: -200px;
            height: 100%;
            width: 500px;
            
            z-index: -1;
            > div{
                opacity: 0.5;
            }
        }
        .back-right{
            display: flex;
            height: 100%;
            width: 500px;
            z-index: 100;
            > div{
                opacity: 0.5;
            }
        }

    }

    .controls{
        position: absolute;
        display: flex;
        z-index: 100;
        height: 100%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        justify-content: space-between;
        pointer-events: none;
        
        .button{
            pointer-events: all;
            cursor: pointer;
            display: flex;
            padding: 20px;
            align-items: center;
            color: #ffffff;
            font-size: 50px;
        }
    }
`;


const Features= () => {

    const ref = useRef<HTMLDivElement>(null);

    document.addEventListener('scroll',() => {
    
        if(!ref.current)
            return;

        let offsetTop = ref.current.getBoundingClientRect().top

        var top  = window.pageYOffset || document.documentElement.scrollTop
        var height = window.innerHeight;

        console.log(top,offsetTop)

        if(offsetTop < height*0.3){
       //    ref.current.classList.remove("hidden");
         
            var photos = ref.current.getElementsByClassName('feature');
       
            Array.prototype.slice.call( photos ).forEach((element:HTMLDivElement) => {
                element.classList.remove('hidden');
            })
       
        }

    });
    

    const items = [
        <Feature style={{animationDelay: '100ms',zIndex: 20}} picture={FEATURE1} title="Academia / Sauna/ Vestuário"/>,
        <Feature style={{animationDelay: '200ms',zIndex: 19}} picture={FEATURE2} title="Área Gourmet"/>,
        <Feature style={{animationDelay: '300ms',zIndex: 18}} picture={FEATURE3} title="Quadra de Esportes"/>,
        <Feature style={{animationDelay: '400ms',zIndex: 17}} picture={FEATURE4} title="Piscina"/>,
        <Feature style={{animationDelay: '500ms',zIndex: 16}} picture={FEATURE5} title="Espaço Kids"/>,
        <Feature style={{animationDelay: '600ms',zIndex: 15}} picture={FEATURE6} title="Salão de Eventos"/>,
    ]

    const maxIndex = items.length - Math.floor(window.innerWidth/300);

    const [index,setIndex] = useState(0);


    var show = [];
    
    show = [items[items.length-1],...items,items[0]]

    const next = () => {
        if(index < maxIndex) setIndex(index+1);
    }
    const back = () => {
        if(index > 0) setIndex(index-1);
    }

    var LastElement = <Feature style={{animationDelay: '700ms',zIndex: 14,opacity: 0.5}} picture={FEATURE1} title="Academia / Sauna/ Vestuário"/>
    var FirstElement = <Feature style={{animationDelay: '0ms',zIndex: 21,opacity: 0.5}} picture={FEATURE6} title="Salão de Eventos"/>


  return <Container ref={ref}>

        <div className='controls'>
            <div onClick={back} className='button'>
                {index>0&&<div>{`<`}</div>}
            </div>
            <div onClick={next} className='button'>
                {index<maxIndex&&<div>{`>`}</div>}
            </div>
        </div>


        <div className='content' style={{transform: `translateX(-${300*index}px)`}}>
            <div className='back-left'>{FirstElement}</div>
            <div className='scrollview'> {items}</div>
            <div className='back-right'>{LastElement}</div>
        </div>
  </Container>;

}

const FeatureContainer = styled.div`
   clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
   height: 100%;
   width: 400px;
   margin-left: -100px;
   background-color: #ffffff;
   transition: 500ms !important;
   flex-shrink: 0;
   position: relative;
   cursor: pointer;
   

   .info{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       padding: 80px;
       width: 100%;
       height: 100%;
   }

   .background{
    
       width: 100%;
       height: 100%;
       background-size: cover;
       position: absolute;
       left: 0;
       right: 0;
       top: 0;
       bottom: 0;
       background-position: center;
   }
 

   &:hover{
        width: 500px;
       
        clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
        //clip-path: polygon(12.5% 0%, 100% 0%, 87.5% 100%, 0% 100%);
       
   }

`;

const AniContainer = styled.div`

@keyframes anima {
       0%{
          
            
            opacity: 0;
            transform: translateX(-100px);
       }
       100%{
            opacity: 1;
       }
   }

  

   &.hidden{
        opacity: 0 !important;
        > div{
            width: 500px;
            clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
        }
    }
    &:not(.hidden){
        animation: anima 1s both;
      //  opacity: 1;
    }
`

const Feature= ({picture,title,description,icon,style}:any) => {


    return <AniContainer style={style} className="feature hidden"><FeatureContainer >
        <div className='info'>
            <Heading color="#fff" align="center" title={title} size={5} bold/>
            <Heading color="#fff" title="ad asdopasjdj asjdasj djaskjdk asjkdjak sjdaskç djkasj dj" align='center' size={7} />
        </div>

        <div className='background' style={{backgroundImage: `url(${picture})`}}/>
       <div className='background' style={{backgroundColor: `rgba(0,0,0,0.5)`}}/>
     
    </FeatureContainer></AniContainer>;
 
}

export default Features;