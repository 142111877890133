import React from 'react';
import Header from './components/Header';
import Empty from './sessions/Empty';
import Features from './sessions/Features';
import Galery from './sessions/Galery';
import Home from './sessions/Home';
import Info from './sessions/Info';
import Shape from './sessions/Shape';
import Map from './sessions/Map';
import Heading from './components/Heading';
import CenterWithFeatures from './sessions/CenterWithFeatures';
import CenterWithFeatures2 from './sessions/CenterWithFeatures2';
function App() {
  return (
    <>
      <Header/>
      <Home/>
      <Empty/>
      {/* <div style={{backgroundColor: "#FFFFFF"}}> */}
        <Shape/>
        <CenterWithFeatures/>
        <CenterWithFeatures2/>
        {/* <Info align="LEFT" title="Portaria" description="Portaria projetada para proporcionar total segurança aos moradores, com triagem de veículos e controle de pessoas com catraca eletrônica."/> */}
        {/* <Info align="RIGHT" title="Espaço Kids" description="Espaço infantil livre, lúdico, divertido e organizado para as crianças poderem socializar e brincar à vontade com conforto e segurança."/> */}
        {/* <Galery/> */}
        <Map/>
        <Features/>
      {/* </div> */}
    </>
  );
}

export default App;
