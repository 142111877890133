import {createGlobalStyle} from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family: Sans-serif;
       
    }

    html,body {
        scroll-behavior: smooth;
    }



    @keyframes fadeOut {
        0%{
            opacity: 1
        }
        100%{
            opacity: 0
        }
    }
    @keyframes fadeIn {
        0%{
            opacity: 0
        }
        100%{
            opacity: 1
        }
    }
    @keyframes slideFromBottom {
        0%{
            transform: translateY(100px);
            opacity: 0
        }
        80%{
            opacity: 1
        }
        100%{
            transform: translateY(0px)
        }
    }
`;
