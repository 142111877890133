import React, { useRef } from 'react';
import styled from 'styled-components';
import Heading from '../components/Heading';

//@ts-ignore
import ICON1 from '../assets/CAMERA_ICON.png'
//@ts-ignore
import ICON2 from '../assets/CANCELA_ICON.png'
//@ts-ignore
import ICON3 from '../assets/SECURITY_ICON.png'

export const Container = styled.div`
    display: flex;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 10;
    justify-content: center;
    overflow: hidden;
    padding-top: 50px;
    
    .content{
        /* padding-top: 400px; */
        max-width: 1000px; 

        &.animation{
            .heading{
                animation: slideFromBottom 500ms both;
            }
            .feature {
                animation: slideFromBottom 300ms  both;
            }
        }
        &:not(.animation){
            .heading{
                opacity: 0;
                animation: fadeOut 500ms 
                animation-delay: 0ms;
            }
            .feature{
                opacity: 0;
                animation: fadeOut 500ms 
                animation-delay: 0ms;
            }
         
        }

        .info {
            display: flex;
            flex-direction: column;

            .features{
                flex: 1;
                padding: 20px 70px;
                display: flex;
                justify-content: center;
                padding-top: 50px;
                
                .feature{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;

                    &:nth-child(2){
                        margin-top: -50px;
                    }

                    img{
                        width: 80px;
                    }
                }
            }
            .details{
                flex: 1;
                padding: 20px 70px;
                flex-direction: column;
                display: flex;
                justify-content: center;

                &.hidden{
                    opacity: 0;
                }
                &:not(.hidden){
                    opacity: 1;
                    animation: slideFromBottom 1s;
                }
                
            }
            .image{
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                &.hidden{
                    opacity: 0;
                }
                &:not(.hidden){
                    opacity: 1;
                    animation: fadeIn 1s;
                }

                img{
                    width: 400px;
                }
            }
           
        }
    }

    .left{

        padding: 20px;
        display: flex;
        align-items: center;

        .info{
            max-width: 600px;
        }
    }
    .right{

    }

  
`;


const CenterWithFeatures= ({align,title,description,image}:any) => {

    const ref = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLDivElement>(null);
    const detailsRef = useRef<HTMLDivElement>(null);

    document.addEventListener('scroll',() => {
    
        if(!ref.current )
            return;

        let offsetTop = ref.current.getBoundingClientRect().top

        var top  = window.pageYOffset || document.documentElement.scrollTop
        var height = window.innerHeight;


        if(offsetTop > height){
            ref.current.classList.remove('animation');
        }
        else if(offsetTop < height*0.5){
            ref.current.classList.add('animation');
        }
      

    });


  return <Container>
      <div className="content" ref={ref}>
        
        <div className='info'>
          
            <div ref={detailsRef} className='details'>
                <Heading align="center" color="#333"  bold title="Segurança" size={6}/>
                <Heading style={{animationDelay: '250ms'}} align="center" color="#333"  bold title="Portaria" size={1}/>
                <Heading style={{animationDelay: '500ms'}} align="center" color="#333" title={`Portaria projetada para proporcionar total segurança aos moradores, com triagem de veículos e controle de pessoas com catraca eletrônica.`} size={4}/>
            </div>

            <div className='features'>
                <div className='feature'  style={{animationDelay: "500ms"}}>
                    <img src={ICON1}/>
                    <Heading align="center" color="#333" title={`Camera de segurança`} size={5}/>
                    <Heading align="center" color="#888" title={`Segurança para sua família em tempo real`} size={6}/>
                </div>
                <div className='feature'  style={{animationDelay: "600ms"}}>
                    <img src={ICON2}/>
                    <Heading align="center" color="#333" title={`Cancela`} size={5}/>
                    <Heading align="center" color="#888" title={`Identificação de veículos e dos passageiros`} size={6}/>
                </div>
                <div className='feature' style={{animationDelay: "700ms"}}>
                    <img src={ICON3}/>
                    <Heading align="center" color="#333" title={`Porteiro`} size={5}/>
                    <Heading align="center" color="#888" title={`Segurança da portaria, controlando entrada e saída dos condôminos `} size={6}/>
                </div>
            </div>

        </div>

      
      </div>
    
  </Container>;
}

export default CenterWithFeatures;