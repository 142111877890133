import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    
    height: 70px;
    width: 100%;
    position: absolute;
    z-index: 1000;
    /* border-bottom: 2px solid #ddd; */
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .left{

        padding: 20px;
        display: flex;
        
    }
    .right{
        display: flex;
        padding: 0 20px;
        div {
            padding: 10px 30px;
            border-bottom: 1px solid transparent;
            color: #fff;
            cursor:pointer;
            font-weight: 600;

            
        }
    }
    @media(max-width: 600px){
       display: none;
    }
`;


const Home= () => {
  return <Container>
      <div className="left">
         
      </div>
      <div className="right">
            {/* <div>Início</div>
            <div>Galeria</div>
            <div>Mapa</div>
            <div>Sobre</div>
            <div>Contato</div> */}
      </div>
  </Container>;
}

export default Home;